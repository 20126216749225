<template>
    <div class="customer">
        <el-card style="margin-bottom:10px;" v-if="mid" v-loading="load"
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="上传中...">
            <div style="display:flex;flex-direction:row">
            <el-upload :action="fileupload" :limit="1" :file-list="fileList" :on-change="handleChange" :on-success="UploadSuccess" >
                <el-button type="primary" :disabled='!mid?true:false' size="small" >点击上传文件</el-button>
            </el-upload>
            <el-tag style="margin-left:10px;" class="el-icon-warning" type="warning"> 请上传手机号txt文件进行导入</el-tag>
            </div>
        </el-card>
        <el-card>
            <template #header>
                <span>客户列表</span>
            </template>
            <div class="content">
                <div>
                    <el-select v-model="merchantid" filterable clearable size="small" v-if="ismerchant != 1" style="width: 100px;">
                        <el-option v-for="item in merchants" :key="item" :value="item.ID" :label="item.Merchant"></el-option>
                    </el-select>
                    <el-input v-model="keyword" size="small" placeholder="请输入手机" style="width:250px;margin-left:20px;"></el-input>
                    <el-select v-model="type" size="small" style="width:150px;margin-left:20px;width: 100px;" placeholder="选择客户来源">
                        <el-option value="1" label="支付宝"></el-option>
                        <el-option value="2" label="微信"></el-option>
                    </el-select>
                    <el-button size="small" type="primary" @click="init" style="margin-left:20px;">搜索</el-button>
                    
                </div>
                <div>
                    <el-table :data="tabledata" border style="width: 100%; margin: 1em 0;"
                                v-loading="load"
                                size="small"
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中...">
                        <el-table-column label="客户编号" prop="MemCode"></el-table-column>
                        <el-table-column label="商户名称" prop="Merchant"></el-table-column>
                        
                        <el-table-column label="客户来源">
                            <template #default="scope">
                                <span v-if="scope.row.Type == 1">支付宝</span>
                                <span v-if="scope.row.Type == 2">微信</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="手机" prop="Mobile"></el-table-column>
                        <el-table-column label="余额" prop="Credit">
                            <template #default="scope">
                                <span v-if="scope.row.Credit">{{scope.row.Credit/100}}</span>
                                <span v-else>0.00</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template #default='scope'>
                                <el-button @click="OrderLog(scope.row.Mobile,scope.row.fkMerchantID)" size="small" type="text">订单记录</el-button>
                                <el-button @click="ConsumeLog(scope.row.Mobile)" size="small" type="text">流水记录</el-button>
                                <el-button @click="EditBalance(scope.row.ID,scope.row.fkMerchantID,scope.$index)" size="small" type="text" >修改余额</el-button>
                                <el-button @click="Log(scope.row.ID,scope.row.fkMerchantID)" size="small" type="text" v-if="mid">查看日志</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
                </div>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="log" title="余额修改日志">
        <el-table :data="logdata" border size="small" style="width: 100%; margin: 1em 0;">
            <el-table-column label="操作人" prop="OpName"></el-table-column>
            <el-table-column label="操作时间">
                <template #default='scope'>
                    {{formatDate(scope.row.AddDTime)}}
                </template>
            </el-table-column>
            <el-table-column label="备注" prop="Remark"></el-table-column>
        </el-table>
    </el-dialog>
</template>
<style scoped>

</style>
<script>
import Cookies from 'js-cookie'
import constant from "@/constant"
export default {
    data(){
        return {
            merchants:[],
            curpage:1,
            pagesize:10,
            totalcount:'',
            keyword:'',
            merchantid:'',
            tabledata:[],
            type:"",
            log:false,
            logdata:[],
            fileupload:constant.fileupload,
            load:false,
            fileList:[],
        }
    },
    methods:{
        UploadSuccess(response){
            console.log(response);
            if(response == "none" || response == "error"){
                this.$message.error("上传失败");
            }else{
                if(response instanceof Array){
                    this.load = true;
                    this.axios.get(constant.uploadmobile,{
                        params:{
                            mobilearr:response,
                            mid:this.mid,
                        }
                    }).then((response)=>{
                        this.fileList = [];
                       this.load = false;
                        if(response.data == "OK"){
                            this.$message.success("操作成功");
                            this.$router.go(0);
                        }else{
                             console.log(response.data);
                            this.$message.error("操作失败");
                        }
                    });
                }
            }
        },
        Log(memberid,merchantid){
            this.axios.get(constant.getbalancelog,{
                params:{
                    memberid:memberid,
                    merchantid:merchantid,
                }
            }).then((response)=>{
                console.log(response.data);
                this.logdata = response.data;
                this.log = true;
            });
        },
        formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2); 
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
        init(){
            this.axios.get(constant.customerlist,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    keyword:this.keyword,
                    merchantid:this.merchantid,
                    mid:this.mid,
                    ismerchant:this.ismerchant,
                    type:this.type
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.totalcount = response.data.totalcount*1;
                this.curpage = response.data.curpage*1;

            });
        },
        handleCurrentChange(val){
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            this.pagesize = val;
            this.init();
        },
        OrderLog(mobile,merchantid){
            //console.log(memname+"," + merchantid);
            this.$router.push({path:'/orderlist',query:{merchantid:merchantid,keyword:mobile}});
        },
        ConsumeLog(mobile){
            this.$router.push({path:'/bill',query:{keyword:mobile}});
        },
        EditBalance(id,mid,index){
            console.log(id);
            this.$prompt("请填写修改后的余额","提示",{
                confirmButtonText:"确定",
                cancelButtonText:"取消",
                inputPattern:/^(0\.0*[1-9]+[0-9]*$|[1-9]+[0-9]*\.[0-9]*[0-9]$|[1-9]+[0-9]*$)/,
                inputErrorMessage: '请输入正确格式的余额（不小于0，小数点保留2位）'
            }).then((obj)=>{
                console.log(obj.value);
                this.axios.get(constant.editbalance,{
                    params:{
                        memberid:id,
                        balance:obj.value,
                        mid:mid,
                        ismerchant:this.ismerchant,
                        opid:this.opid,
                        role:this.role,
                    }
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == "OK"){
                        this.$message.success("操作成功");
                        this.tabledata[index].Credit = obj.value*100;
                    }
                });
            }).catch((err)=>{
                console.log(err);
                return false
            })
        }
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.opid = Cookies.get("id");
        this.role = Cookies.get("role");
        this.axios.get(constant.allmerchant,{
            headers:{
                "content-type":"application/json"
            },
        }).then((response)=>{
            console.log(response.data);
            this.merchants = response.data;
        });
        this.init();
    }
}
</script>